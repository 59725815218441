<script lang="ts" setup>
import uniqBy from 'lodash.uniqby'
import type { Tables } from '@/types/database.types.ts'
const props = defineProps<{
  map: {
    id: string;
    cover_image_url: string;
    spots_count: number;
    spots?: Array<{
      city: {
        name: string;
        country: string;
      };
      category: {
        name: string;
        icon: string;
      };
    }>;
    title: string;
    is_published: boolean;
    spots: Tables<'spots'>[]
    profiles: Tables<'profiles'>;
  },
  version?: string;
  to?: string;
  limited?: boolean;
}>()

// mapdid.vue it will work with cities and categories instead
const mergedCountries = computed(() => {
  if (!props.map.spots) return []
  const cities = props.map.spots
    .filter(spot => spot.city) // Filter out spots without city
    .map((spot) => spot.city)
  return uniqBy(cities, 'country')
})

const mergedCities = computed(() => {
  if (!props.map.spots) return []
  const cities = props.map.spots
    .filter(spot => spot.city) // Filter out spots without city
    .map((spot) => spot.city)
  return uniqBy(cities, 'name')
})

const mergedCategories = computed(() => {
  if (!props.map.spots) return []
  const categories = props.map.spots
    .filter(spot => spot.category) // Filter out spots without category
    .map((spot) => spot.category)
  return uniqBy(categories, 'name')
})
</script>

<template>
  <article :class="`map-card ${version ? version : ''}`">
    <div class="image">
      <NuxtLink :to="to ? to : localePath(`/maps/${map.id}`)">
        <MPImg class="cover" :src="map.cover_image_url || undefined" :width="400" :height="450" />
      </NuxtLink>
      <div v-if="map.profiles && map.profiles.profile_image_url">
        <MPImg
          class="profile"
          :src="map.profiles.profile_image_url || '/media/images/placeholder-user.jpg'"
          :width="100"
          :height="100"
        />
      </div>
    </div>
    <div class="overlay">
      <div v-if="props.map.spots && mergedCountries.length > 0 && mergedCities.length > 0" class="spots-country">
        <NuxtLink :to="to ? to : localePath(`/maps/${map.id}`)">
          {{ mergedCountries[0].country }}&nbsp;-&nbsp;
          <span v-for="(city, idx) in mergedCities.slice(0, 1)" :key="idx">
            {{ idx >= 1 && idx < mergedCities.length - 1 ? ',' : '' }}
            {{ city.name }}
          </span>
          <span v-if="mergedCities.length > 2">
            <small>&nbsp; +{{ mergedCities.length - 2 }} destination{{ mergedCities.length > 3 ? 's' : '' }}</small>
          </span>
        </NuxtLink>
      </div>
      <h4 v-if="map.title" class="title">
        <NuxtLink :to="to ? to : localePath(`/maps/${map.id}`)">
          {{ map.title }}
        </NuxtLink>
      </h4>
      <div v-if="map.is_published" class="spots">
        <i class="ri-map-pin-fill" />
        <small>
          {{ map.spots_count }} {{ $t('explore.spots') }}
        </small>
      </div>
      <small v-if="map.profiles && map.profiles.mappr_handle" class="author">
        Made by <NuxtLinkLocale :to="`/@${map.profiles.mappr_handle}`">@{{ map.profiles.mappr_handle }}</NuxtLinkLocale>
      </small>
      <NuxtLink v-if="mergedCategories" :to="to ? to : localePath(`/maps/${map.id}`)" class="spots-category">
        <span v-for="(category, idx) in mergedCategories.slice(0, 2)" :key="idx">
          <small v-if="category && category.name.length > 0" :title="category.name">
            <i :class="`ri-${category.icon}`" />&nbsp;{{ category.name }}
          </small>
        </span>
        <span v-if="mergedCategories.length >= 2">
          <small :title="`+ ${mergedCategories.length - 2} categories`">
          +{{ mergedCategories.length - 2 }}
          </small>
        </span>
      </NuxtLink>
    </div>
  </article>
</template>

<style lang="postcss" scoped>
@import '@/assets/css/breakpoints.pcss';
@import '@/assets/css/typography.pcss';
.map-card {
  position: relative;
  display: grid;
}

.title {
  color: var(--color-slate-900);
  @mixin typeface-regular;
  font-family: 'Hanken Grotesk', sans-serif;
  font-weight: 500;
  margin: 0.5rem;
  margin-top: 0.5rem;
}

.author {
  display: flex;
  margin: 0.5rem;
  gap: 0.2rem;
  a {
    font-weight: 500;
    color: var(--color-rose-500);
  }
}

.spots {
  position: absolute;
  top: 0.8rem;
  right: 0.35rem;
  display: inline-flex;
  margin: 0 0.45rem 0;
  gap: 0.25rem;
  color: var(--color-slate-500);
  font-weight: 600;
  background: var(--color-slate-50);
  padding: 0.25rem 0.3rem;
  border-radius: 0.45rem;
  box-shadow: var(--shadow-sm);
  i {
    color: var(--color-rose-400);
  }
}

a {
  display: flex;
}

.image {
  position: relative;
  height: fit-content;
}

.cover {
  border-radius: 1rem;
  aspect-ratio: 1/1;
  object-fit: cover;
  min-width: 100%;
}

.profile {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  border-radius: 100%;
  overflow: hidden;
  box-shadow: var(--shadow-base);
  border: 2px solid var(--color-slate-200);
}

.spots-country {
  display: inline-flex;
  @mixin typeface-small;
  margin-top: 1rem;
  padding-right: 0.25rem;
  color: var(--color-slate-600);
  small {
    @mixin typeface-small;
    color: var(--color-indigo-400);
  }
}

.spots-country:nth-child(1) {
  padding-left: 0.5rem;
}

.spots-category {
  padding: 0.75rem 0.5rem;
  white-space: nowrap;
  display: inline-flex;
  gap: 0.5rem;
  width: fit-content;
  small {
    display: block;
    max-width: 10ch;
    width: fit-content;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: var(--color-slate-50);
    padding: 0.45rem 0.75rem;
    border-radius: 5rem;
    color: var(--color-slate-900);
    cursor: default;
    i {
      color: var(--color-slate-400);
    }
  }
}
</style>
